import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DocTypesActions } from './actions';
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { DialogsActions } from '../../dialogs/store/actions';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { DocumentTypesApiService } from '../services/document-types-api.service';
import { NotificationsActions } from '../../notifications/store/actions';
import { selectUser } from 'src/app/core/store/selectors';
import { isPresent } from 'src/app/core/utils/isPresent';

@Injectable()
export class FeatureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private documentTypesApiService: DocumentTypesApiService,
    private dialogRef: MatDialog,
    private translocoService: TranslocoService,
  ) {}

  getDocTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.getDocTypes),
      mergeMap((props) => {
        return of(DocTypesActions.loadDocTypes());
      }),
    ),
  );

  loadDocTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.loadDocTypes),
      withLatestFrom(this.store.select(selectUser).pipe(filter(isPresent))),
      switchMap(([props, user]) => {
        return this.documentTypesApiService.getDocumentTypes$().pipe(
          map((docTypes) => {
            return DocTypesActions.setDocTypes({ docTypes });
          }),
          catchError((response) => {
            return of(
              DocTypesActions.loadDocTypesFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'DocTypes api not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  createDocType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.createDocType),
      mergeMap((props) =>
        this.documentTypesApiService.createDocumentType$(props.name).pipe(
          map((docType) => DocTypesActions.createDocTypeSuccess({ docType })),
          catchError((response) => {
            return of(
              DocTypesActions.createDocTypeFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: this.translocoService.translate('errors.createDocType'),
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  createDocTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.createDocTypeSuccess),
      map((props) => {
        this.store.dispatch(DocTypesActions.closeCreatingDocTypeModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.docTypeAdded'),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return DocTypesActions.getDocTypes();
      }),
    ),
  );

  createDocTypeCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.createDocTypeCancel),
      map((props) => {
        //if (props.pristine) {
        return DocTypesActions.createDocTypeCancelled();
        // } else {
        //   return DialogsActions.showDialog({
        //     data: {
        //       actionsKeys: ['yes', 'no'],
        //       content: '',
        //       title: this.translocoService.translate('titles.updatesWillNotBeSaved'),
        //     },
        //     callback: (action) => {
        //       if (action === 'yes') {
        //         this.store.dispatch(DocTypesActions.createDocTypeCancelled());
        //         this.dialogRef.closeAll();
        //         return DialogsActions.setRef({ ref: null });
        //       } else {
        //         return DialogsActions.setRef({ ref: null });
        //       }
        //     },
        //   });
        //}
      }),
    ),
  );

  deleteDocType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.deleteDocType),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteDocType'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(DocTypesActions.deleteDocTypeConfirmed({ id: props.id }));
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                DocTypesActions.deleteDocTypeCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteDocTypeConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.deleteDocTypeConfirmed),
      mergeMap((props) => {
        return this.documentTypesApiService.deleteDocumentType$(props.id).pipe(
          map(() => DocTypesActions.deleteDocTypeSuccess()),
          catchError((response) => {
            return of(
              DialogsActions.setRef({ ref: null }),
              DocTypesActions.deleteDocTypeFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'DocTypes API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  deleteDocTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.deleteDocTypeSuccess),
      map(() => {
        return DocTypesActions.getDocTypes();
      }),
    ),
  );

  loadDocType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.loadDocType),
      switchMap(({ id }) => {
        return this.documentTypesApiService.getDocumentTypeById$(id).pipe(
          map((docType) => DocTypesActions.setDocType({ docType })),
          catchError((response) => {
            return of(
              DocTypesActions.loadDocTypeFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'DocTypes api not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  editDocTypeCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.editDocTypeCancel),
      map((props) => {
        //if (props.pristine) {
        return DocTypesActions.editDocTypeCancelled();
        // } else {
        //   return DialogsActions.showDialog({
        //     data: {
        //       actionsKeys: ['yes', 'no'],
        //       content: '',
        //       title: this.translocoService.translate('titles.updatesWillNotBeSaved'),
        //     },
        //     callback: (action) => {
        //       if (action === 'yes') {
        //         this.store.dispatch(DocTypesActions.editDocTypeCancelled());
        //         this.dialogRef.closeAll();
        //         return DialogsActions.setRef({ ref: null });
        //       } else {
        //         return DialogsActions.setRef({ ref: null });
        //       }
        //     },
        //   });
        // }
      }),
    ),
  );

  editDocType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.editDocType),
      mergeMap((props) =>
        this.documentTypesApiService.updateDocumentType$(props.name, props.id).pipe(
          map((docType) => DocTypesActions.editDocTypeSuccess({ docType })),
          catchError((response) => {
            return of(
              DocTypesActions.editDocTypeFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'DocTypes api not available',
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  editDocTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocTypesActions.editDocTypeSuccess),
      map((props) => {
        this.store.dispatch(DocTypesActions.closeEditingDocTypeModal());
        this.dialogRef.closeAll();
        DialogsActions.setRef({ ref: null });
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.docTypeUpdated'),
            action: 'x',
          }),
        );

        return DocTypesActions.getDocTypes();
      }),
    ),
  );
}
