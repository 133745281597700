import { GetDocumentTypeDto } from '../models/doc-types.model';

export interface DocTypesState {
  docTypes: GetDocumentTypeDto[] | null;
  loadingDocTypes: boolean;
  creatingDocType: boolean;
  closeCreatingDocTypeModal: boolean;
  deletingDocType: boolean;
  docType: GetDocumentTypeDto | null;
  loadingDocType: boolean;
  editingDocType: boolean;
  closeEditingDocTypeModal: boolean;
}

export const initialState: DocTypesState = {
  docTypes: null,
  loadingDocTypes: false,
  creatingDocType: false,
  closeCreatingDocTypeModal: false,
  deletingDocType: false,
  docType: null,
  loadingDocType: false,
  editingDocType: false,
  closeEditingDocTypeModal: false,
};
