import { createReducer, on } from '@ngrx/store';
import { DocTypesActions } from './actions';
import { initialState } from './state';

export const featureReducer = createReducer(
  initialState,
  on(DocTypesActions.setDocTypes, (state, { docTypes }) => ({
    ...state,
    docTypes: docTypes,
    loadingDocTypes: false,
  })),
  on(DocTypesActions.loadDocTypes, (state, {}) => ({
    ...state,
    loadingDocTypes: true,
  })),
  on(DocTypesActions.loadDocTypesFail, (state, {}) => ({
    ...state,
    loadingDocTypes: false,
  })),
  on(DocTypesActions.createDocType, (state, {}) => ({
    ...state,
    creatingDocType: true,
    closeCreatingDocTypeModal: false,
  })),
  on(DocTypesActions.createDocTypeCancel, (state, {}) => ({
    ...state,
    creatingDocType: false,
    closeCreatingDocTypeModal: false,
  })),
  on(DocTypesActions.createDocTypeSuccess, (state, {}) => ({
    ...state,
    creatingDocType: false,
    closeCreatingDocTypeModal: false,
  })),
  on(DocTypesActions.createDocTypeFail, (state, {}) => ({
    ...state,
    creatingDocType: false,
    closeCreatingDocTypeModal: false,
  })),
  on(DocTypesActions.createDocTypeCancelled, (state, {}) => ({
    ...state,
    closeCreatingDocTypeModal: true,
  })),
  on(DocTypesActions.closeCreatingDocTypeModal, (state, {}) => ({
    ...state,
    closeCreatingDocTypeModal: true,
  })),
  on(DocTypesActions.clearCreateDocTypeCancelled, (state, {}) => ({
    ...state,
    closeCreatingDocTypeModal: false,
  })),
  on(DocTypesActions.deleteDocType, (state, {}) => ({
    ...state,
    deletingDocType: true,
  })),
  on(DocTypesActions.deleteDocTypeCancel, (state, {}) => ({
    ...state,
    deletingDocType: false,
  })),
  on(DocTypesActions.deleteDocTypeSuccess, (state, {}) => ({
    ...state,
    deletingDocType: false,
  })),
  on(DocTypesActions.deleteDocTypeFail, (state, {}) => ({
    ...state,
    deletingDocType: false,
  })),
  on(DocTypesActions.setDocType, (state, { docType }) => ({
    ...state,
    docType: docType,
    loadingDocType: false,
  })),
  on(DocTypesActions.loadDocType, (state, {}) => ({
    ...state,
    loadingDocType: true,
  })),
  on(DocTypesActions.loadDocTypeFail, (state, {}) => ({
    ...state,
    loadingDocType: false,
  })),
  on(DocTypesActions.editDocType, (state, {}) => ({
    ...state,
    editingDocType: true,
    closeEditingDocTypeModal: false,
  })),
  on(DocTypesActions.editDocTypeCancel, (state, {}) => ({
    ...state,
    editingDocType: false,
    closeEditingDocTypeModal: false,
  })),
  on(DocTypesActions.editDocTypeSuccess, (state, {}) => ({
    ...state,
    editingDocType: false,
    closeEditingDocTypeModal: false,
  })),
  on(DocTypesActions.editDocTypeFail, (state, {}) => ({
    ...state,
    editingDocType: false,
    closeEditingDocTypeModal: false,
  })),
  on(DocTypesActions.editDocTypeCancelled, (state, {}) => ({
    ...state,
    docType: null,
    closeEditingDocTypeModal: true,
  })),
  on(DocTypesActions.closeEditingDocTypeModal, (state, {}) => ({
    ...state,
    docType: null,
    closeEditingDocTypeModal: true,
  })),
  on(DocTypesActions.clearEditDocTypeCancelled, (state, {}) => ({
    ...state,
    closeEditingDocTypeModal: false,
  })),
);
